import React from "react";
import { css } from "goober";

import { appURLs } from "@/app/constants.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import PatchNotes1321 from "@/game-lol/patch-notes/13-21.mdx";
import GameArticle from "@/shared/GameArticle.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const PATCH_TO_PATCH_NOTES_MAP = {
  13.21: {
    title: ["", "LoL Patch 13.21 Notes"],
    subTitle: ["", "Patch 13.21 Notes"],
    description: [
      "",
      "Dive into our comprehensive LoL Patch 13.21 notes, uncovering the strategic shifts in champion balance with nerfs to Aurelion Sol, Briar, Caitlyn, Dr Mundo, Jinx, and Rammus, and buffs to Bel'veth, Hecarim, K'Sante, LeBlanc, Master Yi, Morgana, Tahm Kench, and Varus. Discover how adjusted champions like Brand, Seraphine, and Zyra are becoming more versatile across different roles. Plus, get the lowdown on the latest in Nexus Blitz, Cafe Cuties, the Mythic Shop, and ARAM balances—all while gearing up for the thrilling Worlds Clash!",
    ],
    date: new Date("Oct 24, 2023 22:00:00"),
    content: PatchNotes1321,
  },
};

const ChampionImgStyle = () => css`
  width: 64px !important;
  height: 64px !important;

  margin-top: unset !important;
`;

const ChampionHeader = ({ championName, championKey, summary }) => {
  return (
    <div className="flex gap-4">
      <ChampionImg championKey={championKey} className={ChampionImgStyle()} />
      <div className="flex column">
        <p className="type-title--bold">{championName}</p>
        <p className="type-subtitle shade1">{summary}</p>
      </div>
    </div>
  );
};

function PatchNotes() {
  const { parameters } = useRoute();
  const patch = parameters[0];
  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return (
    <GameArticle
      articleHeader={{
        title: content.title,
        subTitle: content.subTitle,
        date: content.date,
        imageURL: `${appURLs.CDN}/blitz/lol/articles/lol-patch-notes.webp`,
      }}
      ContentMDX={content.content}
      components={{ ChampionHeader: ChampionHeader }}
    />
  );
}

export default PatchNotes;

export const meta = (parameters) => {
  const patch = parameters[0];

  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return {
    title: content.title,
    description: content.description,
    subtitle: true,
    image: {
      url: `${appURLs.CDN}/blitz/lol/articles/lol-patch-notes.webp`,
      alt: content.title,
      width: 656,
      height: 369,
    },
  };
};
